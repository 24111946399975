import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { navRoutes } from "routes/routes";
import { AppContext } from "context/AppContext";
import { SVGComponent } from "components/SVGComponent/SVGComponent";
import { MobileMenu } from "components/MobileMenu/MobileMenu";

export const Navbar = ({ countries }) => {
	const intl = useIntl();
	const { countryData, setCurrentCountry } = useContext(AppContext);
	const [countriesList, setCountriesList] = useState([]);
	const { hash } = useLocation();

	const { flag, text } = countryData;

	useEffect(() => {
		if (countriesList.length === 0) {
			setCountriesList(countries);
		}
	}, [countries, countriesList.length]);

	const handleOnSelectChange = (value) => {
		const newCountry = countries.find(
			(country) => country.country_code === value
		);
		if (newCountry) {
			setCurrentCountry(newCountry);
		}
	};

	const handleOnClick = (elementId) => {
		const element = document.querySelector(`#${elementId}`);

		if (element.classList.contains("h-auto")) {
			element.classList.remove("h-auto");
			element.classList.add("h-0");
		} else {
			element.classList.remove("h-0");
			element.classList.add("h-auto");
		}
	};

	return (
		<header className="flex justify-between h-auto items-center py-2.5 flex-wrap fixed z-40 bg-white top-0 right-0 left-0 shadow-xl">
			<div className="w-1/4 lg:w-1/6">
				<a href="/" className="flex justify-center">
					<img
						className="w-3/4 h-full object-cover"
						src={`${process.env.PUBLIC_URL}/assets/efektivos_logo.png`}
						alt="Efektivos inicio"
					/>
				</a>
			</div>
			<MobileMenu
				countriesList={countriesList}
				flag={flag}
				text={text}
				handleOnClick={handleOnClick}
				handleOnSelectChange={handleOnSelectChange}
			/>
			<nav className="w-3/5 hidden xl:block">
				<ul className="flex justify-between">
					{navRoutes(intl).map((route) => (
						<li key={route.path}>
							<a
								href={route.path}
								className={`py-4 block ${
									hash === route.path ? "font-bold" : ""
								} transition ease-in-out delay-400 hover:font-bold`}
							>
								{route.label}
							</a>
						</li>
					))}
				</ul>
			</nav>
			<div className="w-1/6 justify-center items-center hidden xl:flex">
				<div className="rounded-full bg-indigo-900 svg-icon p-2 mr-3 transition ease-in-out delay-150 hover:scale-110 duration-300">
					<a
						href="https://www.facebook.com/gt.efektivos"
						target="_blank"
						rel="noreferrer"
					>
						<SVGComponent url="/assets/facebook.svg" />
					</a>
				</div>
	
				<div className="rounded-full bg-indigo-900 svg-icon p-2 mr-3 transition ease-in-out delay-150 hover:scale-110 duration-300">
					<a href="https://www.instagram.com/efektivos/?hl=es" target="_blank" rel="noreferrer">
						<SVGComponent url="/assets/instagram.svg" />
					</a>
				</div>
				<div className="w-[35px] h-[35px] relative" id="flag-icon-container">
					<img
						className="w-full h-full rounded-full object-cover cursor-pointer"
						src={flag}
						alt={text}
						onClick={() => handleOnClick("toggle-country")}
						id="flag-icon"
					/>
					<div
						id="toggle-country"
						className={`bg-white shadow-lg absolute flex flex-col px-2 rounded-br-xl rounded-bl-xl top-full -left-1.5 overflow-hidden h-0`}
					>
						{countriesList.map((country) => (
							<div className="w-[35px] h-[35px] my-2" key={country.uid}>
								<img
									src={country.flag_url}
									alt={country.name}
									className="w-full h-full rounded-full object-cover cursor-pointer"
									onClick={() => handleOnSelectChange(country.country_code)}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</header>
	);
};
